import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { ElementContainer, Select } from "../../elements";
import { Title } from "./../styles";
import { getData } from "../../../../backend/api";

const BarChart = ({
  title = "Visitor Insights",
  dataType = "API",
  filterType = "JSON",
  filters = [
    { id: 1, value: "January" },
    { id: 2, value: "February" },
    { id: 3, value: "March" },
  ],
  dataItem = "dashboard/barchart",
  columns = [
    { name: "Unique", label: "Unique Users", backgroundColor: "rgb(185, 174, 52)", borderColor: "rgb(185, 174, 52)" },
    { name: "Loyal", label: "Loyal Users", backgroundColor: "rgb(52, 125, 185)", borderColor: "rgb(52, 125, 185)" },
    { name: "New", label: "New Users", backgroundColor: "rgb(52, 185, 163)", borderColor: "rgb(52, 185, 163)" },
  ],
  label = "Month",
}) => {
  const chartRef = useRef(null);
  const [filterItems, setFilterItems] = useState(filters);
  const [mainData, setMainData] = useState(dataItem);
  const [filter, setFilter] = useState("");
  useEffect(() => {
    if (dataType === "API") {
      const fetchData = async (api) => {
        try {
          const response = await getData(filter ? { filter } : {}, api);
          setMainData(response.data);
        } catch (error) {
          console.error("Error fetching data from API:", error);
        }
      };
      fetchData(dataItem);
    }
  }, [dataItem, dataType, filter]);

  useEffect(() => {
    if (filterType === "API") {
      const fetchData = async (api) => {
        try {
          const response = await getData({}, api);
          setFilterItems(response.data);
        } catch (error) {
          console.error("Error fetching filter items from API:", error);
        }
      };
      fetchData(filters);
    }
  }, [filters, filterType]);

  useEffect(() => {
    if (!chartRef.current || !Array.isArray(mainData)) return;

    const data = {
      labels: mainData.map((item) => item[label]),
      datasets: columns.map((column) => ({
        name: column.name,
        label: column.label,
        data: mainData.map((item) => item[column.name]),
        tension: 0.1,
        backgroundColor: column.backgroundColor,
        borderColor: column.borderColor,
        cubicInterpolationMode: "monotone", // Use 'monotone' for curved lines
        pointRadius: 0, // Remove points
        borderWidth: 1,
        barThickness: 10,
      })),
    };

    const ctx = chartRef.current.getContext("2d");

    // Check if there is an existing chart instance
    if (chartRef.current.chart) {
      chartRef.current.chart.destroy();
    }

    chartRef.current.chart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: data.labels,
        datasets: data.datasets,
      },
      options: {
        scales: {
          x: {
            grid: {
              display: false, // Remove y-axis grid lines
              drawBorder: false,
            },
            border: {
              display: false,
            },
            ticks: {
              display: true, // Keep y-axis ticks
            },
            title: {
              display: false,
            },
            drawBorder: false, // Remove y-axis line
          },
          y: {
            grid: {
              display: true, // Remove y-axis grid lines
              color: "rgba(230, 230, 230, 0.33)",
            },
            border: {
              display: false,
            },
            ticks: {
              display: true, // Keep y-axis ticks
            },
            title: {
              display: false,
            },
            drawBorder: false, // Remove y-axis line
          },
        },
        plugins: {
          legend: {
            display: true,
            position: "bottom",
            align: "center",
            fullWidth: false,
            reverse: false,
            labels: {
              color: "black",
              font: {
                size: 12,
                style: "normal",
              },
              padding: 20,
              boxWidth: 10,
              usePointStyle: true,
            },
          },
        },
      },
    });
  }, [mainData, columns, label]);

  return (
    <ElementContainer className="dashitem column">
      <Title>
        <span>{title}</span>
        <Select
          label="Month"
          align="right small"
          value={filter}
          selectApi={filterItems}
          onSelect={(item) => {
            console.log("Selected Value", filter, item);
            setFilter(item.id ?? null);
          }}
        ></Select>
      </Title>
      <canvas ref={chartRef} />
    </ElementContainer>
  );
};

export default BarChart;
