import styled from "styled-components";
export const TitleHead = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: grey;
  display: flex;
  align-items: baseline;
  gap: 0px;
  flex-direction: column;
  
`;
export const Amount = styled.div`
  font-size: 15px;
  font-weight: bold;
  text-align: left;
  padding-left: 0px;
`;
export const Legend = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
  flex-direction: row;
  display: flex;
  gap: 5px;
  align-items: center;
  span{
    font-size:12px;
    white-space:nowrap;
  }
  &.right {
    text-align: right;
  }
  svg {
    justify-content: center;
    align-items: center;
    margin: 5px;
    background: #e8e8e8;
    padding: 10px;
    border-radius: 10px;
  }
`;
export const Line = styled.div`
  height: 30px;
  width: 1px;
  background-color: #dedbdb;
`;
export const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
