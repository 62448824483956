import styled from "styled-components";
export const DashBox = styled.div`
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px 2px;
  border-radius: 12px;
  width: 100%;
  width: calc(${({ width }) => width} - 10px);
  min-height: 150px;
  overflow: auto;    
  box-sizing: border-box;
`;
